import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddToCartItem, Cart } from '../classes/utility';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './localstorage.service';
import {
  SHOPIFY_S,
  SHOPIFY_Y,
} from './shopify-analytics-helpers/cart-constants';
import { getShopifyCookies } from './shopify-analytics-helpers/cookies-utils';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root',
})
export class ShopifyService {
  constructor(
    @Inject(DOCUMENT) private document: Document,

    private localStorage: LocalStorageService,
    private http: HttpClient,
    private themeService: ThemeService,
    private environmentService: EnvironmentService
  ) {}

  private cartSubject = new ReplaySubject<Cart | { state: string }>(1);
  public cartObservable = this.cartSubject.asObservable();

  async getMetaobject() {
    return await this.executeQuery(
      `query getMetaobject($handle: MetaobjectHandleInput!){
        metaobject(handle: $handle) {
          handle
          cart: field(key: "cart") {
            value
          }
        }
      }`,
      {
        handle: { type: 'cart', handle: 'mercury' },
      }
    )
      .toPromise()
      .then((response) => {
        return this.tryParse(response.data.metaobject?.cart?.value ?? '');
      });
  }

  tryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }

  executeQuery(query: string, variables?: any): Observable<any> {
    return this.http.post(
      this.environmentService.environment.apiUrl + '/api/2024-04/graphql.json',
      { query, variables },
      {
        headers: {
          ...(this.environmentService.isBrowser() && {
            'Shopify-Storefront-Y':
              getShopifyCookies(this.document?.cookie ?? '')[SHOPIFY_Y] ??
              undefined,
            'Shopify-Storefront-S':
              getShopifyCookies(this.document?.cookie ?? '')[SHOPIFY_S] ??
              undefined,
          }),
          'X-Shopify-Storefront-Access-Token':
            this.environmentService.environment.storefrontKey,
        },
      }
    );
  }

  private getUserCartId(): Observable<string> {
    const cartId = this.localStorage.getItem(
      this.environmentService.environmentLocale + 'cartId'
    ) as string;
    return cartId?.length > 0 ? of(cartId) : this.createCart();
  }

  addToCart(variants: AddToCartItem[]): Observable<any> {
    this.cartSubject.next({ state: 'loading' });

    const lineItemsToAdd = variants.map((x) => ({
      merchandiseId: btoa(x.variantId),
      quantity: x.quantity || 1,
      attributes: x.attributes,
    }));

    return this.getUserCartId().pipe(
      switchMap((cartId) =>
        this.executeQuery(this.addToCartMutation, {
          cartId: cartId,
          lines: lineItemsToAdd,
        })
      ),
      tap((response: any) => {
        if (response.errors?.length > 0) {
          throw new Error(response.errors);
        }
        if (
          response.data.cartLinesAdd.userErrors.find(
            (x: any) => x.field == 'cartId'
          )
        ) {
          this.localStorage.setItem(
            this.environmentService.environmentLocale + 'cartId',
            response.data.cartLinesAdd.cart.id
          );
        }
        const cart = response.data.cartLinesAdd.cart as Cart;
        if (cart) {
          this.cartSubject.next(cart);
          this.themeService.addToCart(variants, cart);
        } else {
          this.localStorage.removeItem(
            this.environmentService.environmentLocale + 'cartId'
          );
          throw new Error('Clearing Cart');
        }
        return of(cart);
      })
    );
  }

  modifyLineItemQuantity(lines: any): Observable<any> {
    const query = `
      ${this.gqlCartFragment}
      mutation updateCartLineQuantities($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    return this.getUserCartId().pipe(
      switchMap((cartId) =>
        this.executeQuery(query, { cartId: cartId, lines })
      ),
      tap((response: any) => {
        if (response.errors?.length > 0) {
          throw new Error(response.errors[0].message);
        }
        const cart = response.data.cartLinesUpdate.cart as Cart;
        if (cart) {
          this.cartSubject.next(cart);
        } else {
          this.localStorage.removeItem(
            this.environmentService.environmentLocale + 'cartId'
          );
          throw new Error('Clearing Cart');
        }
        return of(cart);
      })
    );
  }

  clearCart() {
    return of();
  }

  removeFromCart(variantIds: string[]): Observable<any> {
    this.cartSubject.next({ state: 'loading' });

    const lineItemsToRemove = variantIds.map((x) => btoa(x));

    return this.getUserCartId().pipe(
      switchMap((cartId) =>
        this.executeQuery(this.removeFromCartMutation, {
          cartId: cartId,
          lineIds: lineItemsToRemove,
        })
      ),
      tap((response: any) => {
        if (response.errors) {
          throw new Error(response.errors);
        }
        const cart = response.data.cartLinesRemove.cart;
        if (cart) {
          this.cartSubject.next(cart);
        } else {
          this.localStorage.removeItem(
            this.environmentService.environmentLocale + 'cartId'
          );
          throw new Error('Clearing Cart');
        }
        return of(cart);
      })
    );
  }

  updateCart(
    cartId: string = this.localStorage.getItem(
      this.environmentService.environmentLocale + 'cartId'
    ) as string
  ): Observable<string> {
    this.cartSubject.next({ state: 'loading' });
    if (!cartId) return of('');

    const query = `
      ${this.gqlCartFragment}
      query getCart($id: ID!) {
        node(id: $id) {
          ... on Cart {
            ...CartFields
          }
        }
      }
    `;

    return this.executeQuery(query, { id: btoa(cartId) }).pipe(
      map((response: any) => response.data.node),
      tap((cart: Cart) => {
        this.cartSubject.next(cart);
        this.getCart();
      }),
      map((cart: Cart) => cart?.id)
    );
  }

  createCart(): Observable<string> {
    if (
      this.localStorage.getItem(
        this.environmentService.environmentLocale + 'cartId'
      )
    ) {
      return this.updateCart().pipe(
        map(
          () =>
            this.localStorage.getItem(
              this.environmentService.environmentLocale + 'cartId'
            ) as string
        )
      );
    } else {
      const query = `
        ${this.gqlCartFragment}
        mutation cartCreate($input: CartInput!) {
          cartCreate(input: $input) {
            cart {
              ...CartFields
            }
          }
        }
      `;
      return this.executeQuery(query, { input: {} }).pipe(
        map((response: any) => response.data.cartCreate.cart),
        tap((cart: Cart) => {
          this.localStorage.setItem(
            this.environmentService.environmentLocale + 'cartId',
            cart.id
          );
          this.cartSubject.next(cart);
        }),
        map((cart: Cart) => cart?.id)
      );
    }
  }

  getCart(): Observable<Cart | { state: string }> {
    return this.cartObservable;
  }

  gqlCartFragment = `
    fragment CartFields on Cart {
      id
      createdAt
      updatedAt
      checkoutUrl
      lines(first: 10) {
        edges {
          node { 
            id
            quantity
            cost {
              subtotalAmount {
                amount 
              }
              totalAmount {
                amount 
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                image { 
                  transformedSrc(maxHeight: 140, maxWidth: 140, crop: CENTER) 
                }
                sku
                selectedOptions {
                  name
                  value
                }
                priceV2 {
                  amount
                  currencyCode
                }
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                product {
                  id
                  handle
                  productType
                  title
                  vendor
                }
              }
            }
            attributes {
              key
              value
            }
          }
        }
      }
      attributes {
        key
        value
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
      buyerIdentity {
        email
        phone
        customer {
          id
        }
        countryCode
        deliveryAddressPreferences {
          ... on MailingAddress {
            address1
            address2
            city
            provinceCode
            countryCodeV2
            zip
          }
        }
        preferences {
          delivery {
            deliveryMethod
          }
        }
      }
    }
  `;

  addToCartMutation = ` 
      ${this.gqlCartFragment}
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `;

  removeFromCartMutation = `
    ${this.gqlCartFragment}
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(
        cartId: $cartId, lineIds: $lineIds
      ) {
        cart {
          ...CartFields
        }
        userErrors {
          field
          message
        }
      }
    }
    `;
}
